@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/variables";

// ==============================================================
// Variables
// ==============================================================

// Box padding
$box-padding-l: tokens.$size-l;
$box-padding-m: tokens.$size-m;
$box-padding-s: tokens.$size-s;

// Box radius
$box-radius-l: tokens.$size-s;
$box-radius-m: tokens.$size-xs;
$box-radius-s: tokens.$size-xxs;

// ==============================================================
// Styles
// ==============================================================

.box {
  padding: tokens.$size-s;
  background: tokens.$color-white;
  @include mixins.transition(tokens.$speed-fast);

  &.--outline {
    border: 1px solid tokens.$color-ink-lightest;
  }

  &.--rounded {
    border-radius: $box-radius-m;
  }

  @each $key, $shadow in variables.$shadows {
    &.--elevation-#{$key} {
      box-shadow: $shadow;
    }

    &.--elevation-hover-#{$key}:hover {
      box-shadow: $shadow;
    }
  }
}
