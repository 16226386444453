@use "@sas-te/alfabeto-tokens/dist/tokens";

.navigation {
  display: flex;
  gap: tokens.$size-xs;
  width: 100%;
  padding: 12px tokens.$size-s;
  border-top: 1px solid tokens.$color-ink-lightest;
}

.endCoachMarkContainer {
  flex: 1;
}

.endCoachMark {
  white-space: nowrap;
}

.dots {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: tokens.$size-s;
  margin-bottom: tokens.$size-m;
  gap: tokens.$size-xs;
}

.dot {
  display: block;
  width: tokens.$size-xs;
  height: tokens.$size-xs;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: tokens.$color-ink-lightest;
  border: 0;
  border-radius: tokens.$border-radius-circle;

  &:disabled {
    cursor: default;
  }

  &.dotActive {
    background-color: tokens.$color-primary;
  }
}
