$assets-path: "@sas-te/alfabeto-tokens/src/assets/";
@use "@sas-te/alfabeto-tokens/dist/tokens";

@import "modern-normalize/modern-normalize.css";
@import "@sas-te/alfabeto-tokens/src/scss/reset";
@import "@sas-te/alfabeto-tokens/src/scss/typography";

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: tokens.$color-ice;
}

hr {
  margin: tokens.$size-m 0;
  border-top: 1px solid tokens.$color-ink-lightest;
}

// stylelint-disable-next-line selector-max-id
#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  transition: all .2s ease;
}
