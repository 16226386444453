@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.defaultLayout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  margin-top: -70px;
  background: tokens.$color-white;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 31%;
    padding-bottom: 22%;
    content: "";
    background: {
      position: top right;
      repeat: no-repeat;
      size: 100%;
      image: url("~App/assets/img/waves.svg");
    }
  }

  .appHeader {
    padding: tokens.$size-s;
  }

  .logo {
    width: 61px;

    @include media-queries.mq-s--mf() {
      width: 81px;
    }

    @include media-queries.mq-m--mf() {
      width: 95px;
    }
  }

  .link {
    display: inline-block;
    line-height: tokens.$line-height-reset;
  }

  .content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  .appFooter {
    padding: tokens.$size-m tokens.$size-l;
    font-size: tokens.$font-size-s;
    text-align: right;
  }

  .footerRights {
    color: tokens.$color-ink-light;
  }
}
