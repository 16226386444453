@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";
@use "@sas-te/alfabeto-tokens/src/scss/functions";
@use "@sas-te/alfabeto-tokens/src/scss/variables";

// stylelint-disable-next-line selector-max-id
:global(#root) {
  padding-top: 70px;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: tokens.$zindex-fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 71px;
  padding-right: tokens.$size-s;
  padding-left: tokens.$size-s;
  background: tokens.$color-white;
  border-top: 3px solid tokens.$color-main;
  border-bottom: 1px solid tokens.$color-ink-lightest;
}

.navbar {
  position: fixed;
  top: 71px;
  right: -100%;
  display: flex;
  flex-direction: column;
  width: 242px;
  height: calc(100% - 71px);
  visibility: hidden;
  background: tokens.$color-white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);

  @include media-queries.mq-xl--mf() {
    position: relative;
    top: 0;
    right: 0;
    flex-direction: row;
    flex-grow: 1;
    height: 100%;
    visibility: visible;
    box-shadow: none;
  }

  &.isNavbarOpen {
    right: 0;
    visibility: visible;
  }
}

.actions {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  order: 3;
}

.actionsPortal {
  display: flex;
  align-items: center;
  padding-right: tokens.$size-s;
}
