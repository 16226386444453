@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/variables";

.input {
  display: inline-block;
}

.inputWrapper {
  position: relative;
}

.inputField {
  position: relative;
  display: block;
  width: 100%;
  padding: variables.$input-v-padding-m variables.$input-h-padding-m;
  font-size: tokens.$font-size-m;
  line-height: tokens.$line-height-input;
  color: tokens.$color-ink;
  background-color: variables.$input-background;
  border: variables.$input-border-size solid variables.$input-border-color;
  border-radius: tokens.$border-radius-m;
  box-shadow: variables.$input-inset-shadow;
  appearance: none;
  @include mixins.transition(tokens.$speed-fast);

  &::placeholder {
    font-family: tokens.$font-family-primary;
    color: tokens.$color-ink-lighter;
  }

  &:disabled {
    @include mixins.disabled-style();
  }

  &:focus {
    @include mixins.input-focus-outline();
  }

  // --------------------------------------------------------------
  // Input Field Sizes
  // --------------------------------------------------------------

  .--large & {
    padding: variables.$input-v-padding-l variables.$input-h-padding-l;
    border-radius: tokens.$border-radius-l;
  }

  .--small & {
    padding: variables.$input-v-padding-s variables.$input-h-padding-s;
    border-radius: tokens.$border-radius-s;
  }

  // --------------------------------------------------------------
  // Input Field Status
  // --------------------------------------------------------------

  .--success & {
    @include mixins.input-focus-outline(tokens.$color-success-light);

    &:focus{
      @include mixins.input-focus-outline(tokens.$color-success-dark);
    }
  }

  .--warning &{
    @include mixins.input-focus-outline(tokens.$color-warning);

    &:focus{
      @include mixins.input-focus-outline(tokens.$color-warning-dark);
    }
  }

  .--error & {
    @include mixins.input-focus-outline(tokens.$color-danger-light);

    &:focus {
      @include mixins.input-focus-outline(tokens.$color-danger-dark);
    }
  }

  // --------------------------------------------------------------
  // Input Field With Icon
  // --------------------------------------------------------------

  // left
  .prepend.--large & {
    padding-left: variables.$icon-size-l;
  }

  .prepend.--medium & {
    padding-left: variables.$icon-size-m;
  }

  .prepend.--small & {
    padding-left: variables.$icon-size-s;
  }

  // right
  .append.--large & {
    padding-right: variables.$icon-size-l;
  }

  .append.--medium & {
    padding-right: variables.$icon-size-m;
  }

  .append.--small & {
    padding-right: variables.$icon-size-s;
  }
}

.inputIcon {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: tokens.$color-ink-lighter;
  pointer-events: none;

  &.inputIconLeft {
    left: variables.$input-border-size;
  }

  &.inputIconRight {
    right: variables.$input-border-size;
  }

  // --------------------------------------------------------------
  // Input Field Icon Sizes
  // --------------------------------------------------------------

  .--large & {
    width: variables.$icon-size-l;
  }

  .--medium & {
    width: variables.$icon-size-m;
  }

  .--small & {
    width: variables.$icon-size-s;
  }
}

// --------------------------------------------------------------
// Container Size
// --------------------------------------------------------------
.--full-width {
  width: 100%;
}
