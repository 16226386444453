
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";
@use "@sas-te/alfabeto-tokens/dist/tokens";

.modalCSAT {
  width: 100%;
  height: 100%;
  margin: 0;

  @include media-queries.mq-l--mf() {
    width: 736px;
    height: 600px;
  }
}

.modalCSATBody {
  height: 100%;
}
