@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/functions";

.documentModal {
  &:global(.sas-modal) {
    width: 720px !important;
    height: 520px;
    overflow: hidden;
  }

  :global(.sas-modal-header) {
    width: 101%;
    margin-bottom: -60px;
  }

  :global(.sas-modal__footer) {
    box-shadow: 0 4px 24px 0 rgba(tokens.$color-black, .25);
  }

  :global(.sas-modal-content) {
    display: flex;
    flex-direction: column;
    text-align: left;

    a {
      color: tokens.$color-primary;

      &:hover {
        text-decoration: underline;
      }
    }

    h2 {
      margin-bottom: tokens.$size-xs;
      text-align: center;
    }

    h3 {
      display: flex;
      align-items: center;
      margin-bottom: tokens.$size-l;
    }

    p {
      color: tokens.$color-ink-light;

      &:not(:last-child) {
        margin-bottom: tokens.$size-m;
      }
    }

    ul {
      margin-bottom: tokens.$size-m;
    }

    li {
      @extend p;

      &::before {
        display: inline-block;
        margin-right: tokens.$size-xxs;
        content: "•";
      }
    }

    hr {
      margin: tokens.$size-m 0;
    }

    table {
      margin-bottom: tokens.$size-m;
      overflow: hidden;
      border-spacing: 0;
      border: 1px solid tokens.$color-ink-lightest;
      border-radius: tokens.$border-radius-m;
    }

    tr {
      &:not(:last-child) {
        td,
        th {
          border-bottom: 1px solid tokens.$color-ink-lightest;
        }
      }
    }

    td,
    th {
      padding: tokens.$size-m;
    }

    thead th {
      padding: tokens.$size-s tokens.$size-m;
      font-size: tokens.$font-size-xs;
      font-weight: tokens.$font-weight-medium;
      color: tokens.$color-ink-light;
      text-transform: uppercase;
      background-color: rgba(tokens.$color-ink-lightest, .25);
      border-bottom: 1px solid tokens.$color-ink-lightest;
    }

    :global(.highlight-box) {
      display: flex;
      align-items: flex-start;
      padding: tokens.$size-s;
      margin-bottom: tokens.$size-l;
      font-size: tokens.$font-size-s;
      background-color: tokens.$color-ice;
      border-radius: tokens.$border-radius-l;

      p {
        color: inherit;
      }
    }

    :global(.highlight-box-warning) {
      color: tokens.$color-secondary-darker;
      background-color: tokens.$color-warning-lightest;
    }

    :global(.highlight-box-info) {
      color: tokens.$color-primary-darker;
      background-color: tokens.$color-primary-lightest;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.document-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}
