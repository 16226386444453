@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";

.radio {
  display: inline-flex;
  align-items: center;
}

.radioField {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: tokens.$size-m;
  height: tokens.$size-m;
  padding: 0;
  border: 1px solid tokens.$color-ink-lighter;
  border-radius: 50%;
  transition: all tokens.$speed-x-fast ease-in-out;
  appearance: none;

  &:not(:checked):not(:disabled) {
    box-shadow: inset 0 2px 2px tokens.$shadow-color;
  }

  &:hover {
    border-color: tokens.$color-ink-light;
    transition: all tokens.$speed-x-fast ease-in-out;
  }

  &:checked {
    background-color: tokens.$color-primary;
    border: none;
    box-shadow: none;

    &::before {
      opacity: 1;
      transition: all tokens.$speed-fast ease-in-out;
      transform: scale(1);
    }
  }

  &::before {
    position: absolute;
    width: 33%;
    height: 33%;
    content: "";
    background: tokens.$color-white;
    border-radius: 50%;
    opacity: 0;
    transition: all tokens.$speed-fast ease-in-out;
  }

  &:focus {
    @include mixins.input-focus-outline();
  }
}

// --------------------------------------------------------------
// Sizes
// --------------------------------------------------------------

.--small {
  .radioField {
    width: 18px;
    height: 18px;

    &::before {
      background-size: 12px;
    }

    &::after {
      width: tokens.$size-m;
      height: tokens.$size-m;
    }
  }
}

// --------------------------------------------------------------
// Container Size
// --------------------------------------------------------------
.--full-width {
  width: 100%;
}
