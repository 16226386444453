@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";

.checkbox {
  display: inline-flex;
  align-items: center;

  $checkbox: &;

  .checkboxField {
    position: relative;
    width: tokens.$size-m;
    height: tokens.$size-m;
    background-color: tokens.$color-white;
    border: 1px solid tokens.$color-ink-lighter;
    border-radius: tokens.$border-radius-xs;
    appearance: none;
    @include mixins.transition(200ms);

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      content: "";
      border-radius: tokens.$border-radius-xs;
      background: {
        position: center;
        repeat: no-repeat;
        size: 0%;
      }
      @include mixins.transition(200ms);

      &:not(:checked):not(:disabled):not(:focus) {
        box-shadow: inset 0 2px 2px rgba(30, 33, 36, .2);
      }
    }

    &:checked {
      background-color: tokens.$color-primary;
      border: none;

      &::before {
        background: {
          image: url("~App/assets/img/check.svg") !important;
          size: 90%;
        }
      }

      &:focus,
      &:hover {
        background-color: tokens.$color-primary-dark;
      }
    }

    &:not(:checked):not(:disabled):not(:focus):hover {
      border-color: tokens.$color-ink-light;
    }

    &:focus {
      border-color: transparent;
      @include mixins.input-focus-outline();
    }

    &:disabled {
      cursor: default;
      background-color: #e5e5e5;
      border-color: tokens.$color-ink-lightest;
    }

    &--indeterminate {
      background-color: tokens.$color-primary;
      border: none;

      &:hover {
        background-color: tokens.$color-primary-dark;
      }

      &::before {
        background: {
          image: url("~App/assets/img/minus.svg");
          size: 70%;
        }
      }
    }
  }

  // --------------------------------------------------------------
  // Sizes
  // --------------------------------------------------------------

  &.--small {
    .checkboxField {
      width: 18px;
      height: 18px;

      &::before {
        background-size: 12px;
      }

      &::after {
        width: tokens.$size-m;
        height: tokens.$size-m;
      }
    }
  }
}

.checkboxLabel {
  margin-left: tokens.$size-xs;
  font-size: tokens.$font-size-m;
  color: tokens.$color-ink;

  &--disabled {
    color: tokens.$color-ink-lightest;
  }
}
