@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/variables";

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  color: tokens.$color-ink-light;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: tokens.$border-radius-m;

  &:hover {
    background-color: tokens.$color-ink-lightest;
  }

  &:not(.isButtonText) {
    background-color: variables.$input-background;
    border: variables.$input-border-size solid variables.$input-border-color;
    box-shadow: tokens.$shadow-s rgba(tokens.$color-black, .15);
  }

  &:not(.isActive):hover {
    background-color: tokens.$color-ice;
    border-color: tokens.$color-ink-lighter;
  }

  &.isActive,
  &:focus {
    @include mixins.input-focus-outline();
  }

  &.isChecked {
    color: tokens.$color-white;

    &,
    &:hover {
      background-color: tokens.$color-primary;
      border-color: tokens.$color-primary;
    }
  }

  &.isFluid {
    width: 100%;
  }

  &:disabled {
    &,
    &.isChecked,
    &:hover {
      color: tokens.$color-ink-lightest;
      cursor: default;
      background-color: tokens.$color-white;
      border-color: tokens.$color-ink-lightest;
      box-shadow: none;
    }
  }

  &.success {
    @include mixins.input-focus-outline(tokens.$color-success-light);

    &:focus {
      @include mixins.input-focus-outline(tokens.$color-success-dark);
    }
  }

  &.warning {
    @include mixins.input-focus-outline(tokens.$color-warning);

    &:focus {
      @include mixins.input-focus-outline(tokens.$color-warning-dark);
    }
  }

  &.error {
    @include mixins.input-focus-outline(tokens.$color-danger-light);

    &:focus {
      @include mixins.input-focus-outline(tokens.$color-danger-dark);
    }
  }
}

.isButtonText {
  font-weight: tokens.$font-weight-semi-bold;
}

.append {
  margin-left: tokens.$size-xxs;
}

.prepend {
  margin-right: tokens.$size-xxs;
}

.--large {
  height: tokens.$element-size-l;
  padding: {
    right: tokens.$size-s;
    left: tokens.$size-s;
  }
}

.--medium {
  height: tokens.$element-size-m;
  padding: {
    right: tokens.$size-xs;
    left: tokens.$size-xs;
  }
}

.--small {
  height: tokens.$element-size-s;
  padding: {
    right: tokens.$size-xxs;
    left: tokens.$size-xxs;
  }
}

.content {
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  min-width: 0;
}

.text,
.counter {
  display: inline-block;
  font-size: tokens.$font-size-m;
  line-height: tokens.$line-height-text;
}

.text {
  flex-grow: 1;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.counter {
  height: 20px;
  padding: 0 tokens.$size-xs;
  margin-left: tokens.$size-xs;
  font-size: .75em;
  color: tokens.$color-primary-dark;
  background-color: tokens.$color-primary-lightest;
  border-radius: 100px;
}

.arrowAndCheckContainer {
  line-height: 0;
}

.arrow {
  width: 20px;
  height: auto;
  vertical-align: middle;

  .isActive & {
    transform: rotate(180deg);
  }
}
