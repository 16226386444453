@use "@sas-te/alfabeto-tokens/dist/tokens";

.dropdown {
  z-index: tokens.$zindex-dropdown;
  padding: 0;
  text-align: left;
  transition: none;

  > :first-child {
    border-top-left-radius: tokens.$border-radius-m;
    border-top-right-radius: tokens.$border-radius-m;
  }

  > :last-child {
    border-bottom-right-radius: tokens.$border-radius-m;
    border-bottom-left-radius: tokens.$border-radius-m;
  }
}

.dropdownFluid {
  width: auto;
  max-width: 354px;
}

.body {
  padding: tokens.$size-s;
}
