@use "sass:math";

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/functions";

.option {
  display: flex;
  align-items: center;
  padding: math.div(tokens.$grid-gutter-width, 2) tokens.$size-s;
  font-size: tokens.$font-size-s;
  cursor: pointer;

  &:not(.disabled):hover {
    background-color: tokens.$color-ice;
  }

  &:focus {
    background-color: tokens.$color-ink-lightest;
    outline: none;
  }

  .isGrouped & {
    padding-left: tokens.$size-l;
  }

  .isControlVisible & {
    padding-left: tokens.$grid-gutter-width;
  }

  &.hasAvatar {
    padding-left: tokens.$size-s;

    .textBox {
      padding-left: tokens.$size-s;
    }
  }
}

li li .option {
  padding-left: tokens.$size-l;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: .5;
}

.control {
  margin-right: tokens.$size-s;
  line-height: 0;
}

.text {
  display: inline-block;
  width: 100%;
  font-size: tokens.$font-size-m;
  font-weight: tokens.$font-weight-regular;
  line-height: tokens.$line-height-text;
  color: tokens.$color-ink;
}

.groupHeader {
  display: flex;
  align-items: flex-start;
  padding: math.div(tokens.$grid-gutter-width, 2) tokens.$size-s;
  font-size: tokens.$font-size-xs;

  :global(.sas-checkbox-field) {
    cursor: pointer;
  }
}

.groupHeaderTitle {
  font-size: tokens.$font-size-xs;
  font-weight: tokens.$font-weight-medium;
  line-height: tokens.$line-height-text;
  color: tokens.$color-ink-light;
  text-transform: uppercase;
}

.avatar {
  margin-right: tokens.$size-s;
}

.optionText {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
}

.subText {
  width: 100%;
  font-size: tokens.$font-size-s;
  line-height: tokens.$line-height-text;
  color: tokens.$color-ink-light;

  .isControlVisible & {
    margin-left: tokens.$size-s;
  }
}

.selectedIconContainer {
  padding-left: tokens.$size-xxs;
  line-height: 0;
}

.selectedIcon {
  width: 22px;
  color: tokens.$color-primary;
}
