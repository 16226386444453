@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.footer {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;

  @include media-queries.mq-l() {
    flex-direction: column;
    justify-content: space-between;
    max-width: 500px;
  }
}

.managePermissionsBtn {
  margin-right: tokens.$size-s;
}

.slideTop {
  height: 0;
  overflow: hidden;
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-transition: .3s;
  transition: .3s;
}

.slideTopActive {
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #c9cccf;
}
